<template>
  <div>
    <section>
      <div class="box p-2 mt-2">
        <div
          class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row"
          role="tablist"
        >
          <router-link v-for="(item, index) in items"  :key="index"
                       :id="item.id"
                       :to="item.to"
                       :class="item.class"
                       class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <i class=" w-4 h-4 mr-2 pi" :class="item.icon"/>
            {{ item.label }}
          </router-link>
        </div>
      </div>
    </section>
    <router-view />
  </div>
</template>
<script>
/* eslint-disable object-curly-spacing */
import { useRoute } from 'vue-router'
import {computed} from 'vue'
export default {
  name: 'LicMenu',
  setup () {
    const route = useRoute()
    const getActive = (routeNames = []) => {
        if (routeNames.includes(route.name)) {
          return 'active'
        }
        return ''
    }
    const items = computed(() => {
       return [
        {
          id: 'lic-ver',
          to: { name: 'pharmasan.oportunidades.licitaciones.ver-licitaciones' },
          class: getActive([
            'pharmasan.oportunidades.licitaciones.ver-licitaciones',
            'pharmasan.oportunidades.licitaciones.cargar-documentos',
            'pharmasan.oportunidades.licitaciones.modificar-indices'
          ]),
          icon: 'pi pi-list',
          label: 'Listado de licitaciones'
        },
        {
          id: 'lic-settings',
          to: { name: 'pharmasan.oportunidades.licitaciones.herramientas' },
          class: getActive([
            'pharmasan.oportunidades.licitaciones.herramientas',
            'pharmasan.oportunidades.licitaciones.herramientas.documentos',
            'pharmasan.oportunidades.licitaciones.herramientas.licitaciones',
            'pharmasan.oportunidades.licitaciones.editar',
            'pharmasan.oportunidades.licitaciones.herramientas.logs',
            'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas',
            'pharmasan.oportunidades.licitaciones.herramientas.licitacion-indicadores',
            'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas',
            'pharmasan.oportunidades.licitaciones.herramientas.socios',
            'pharmasan.oportunidades.licitaciones.herramientas.indicadores-participacion',
            'pharmasan.oportunidades.licitaciones.herramientas.socios.estados-financieros'
          ]),
          icon: 'pi pi-cog',
          label: 'Herramientas del sistema'
        }
      ]
    })
    return {
      getActive,
      items
    }
  }
}
</script>
<style scoped>

</style>
